@import "../../globalStyles/component.scss";

.page {
  background-color: #000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .form {
    background-color: #121212;
    border: 1px solid #313131;
    padding: 0px 6rem;
    padding-top: 3rem;
    padding-bottom: 8.4rem;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .img {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 252px;
      .logo {
        width: 187px;
        height: auto;
        object-fit: contain;
        margin: 12px 10px;
      }
    }

    .header {
      font-family: $font-family-mulish;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.03em;
      text-align: left;
      color: #aaaaaa;
    }

    .inputBox {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      width: 45rem;

      .input {
        margin-top: 3rem;
      }

      .header {
        font-family: $font-family-mulish;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1rem;
      }

      .forget:hover {
        cursor: pointer;
        color: #d1d1d1;
      }
    }

    .btn {
      width: 100%;
      margin-top: 8rem;
    }
  }
}

.errorText {
  font-family: $font-family-mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 1rem;
  color: #dd4e47;
}
