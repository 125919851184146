@import "../../globalStyles/component.scss";

.page {
  height: 100%;
  width: 100%;

  .first {
    width: 100%;
    height: fit-content;

    .topBox {
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: row;

      .imgbox {
        height: 15rem;
        width: 15rem;
        margin-right: 3rem;

        .thumbNail {
          width: 100%;
          height: 100%;
          font-size: 10rem;
          line-height: 15rem;
        }
      }

      .detailsBox {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 50%;
        max-width: 550px;

        .box1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .firstLine {
          @include secondaryTitleText();
          max-width: 30rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .secondLine {
          @include secondaryText();
          display: flex;
          margin-top: 1rem;

          .artistName {
            max-width: 15rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 4.5rem;
          }

          .playlistName {
            max-width: 200px;
            white-space: nowrap;
            // overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            cursor: pointer;

            .customTooltip {
              position: absolute;
              top: 100%;
              left: 0;
              display: none;
              z-index: 2;
              background-color: black;
              padding: 8px;
            }

            &:hover {
              .customTooltip {
                display: flex;
              }
            }
          }
        }

        .thirdLine {
          width: 100%;
          height: 60px;
          margin-top: 1.8rem;
        }
      }

      .likeBox {
        height: 100%;
        // width: 33%;
        display: flex;

        .icon {
          cursor: pointer;
        }

        .btn {
          margin-left: 3.5rem;
          width: 12rem;
        }
      }
    }

    .midBox {
      @include secondaryText();
      padding: 1.2rem 0;
      padding-left: 12.1rem;
      padding-right: 6rem;
      color: $tertiary_white;
      border-bottom: 1px solid #505050;
      width: 100%;
      height: 5rem;
      margin-top: 2rem;
      display: flex;
      justify-content: space-between;

      .text {
        @include secondaryText();
        width: 15rem;
        overflow: hidden;
        color: $tertiary_white;
        border-left: 1px solid #505050;
        padding-left: 1rem;
      }

      .text1 {
        text-align: end;
        width: fit-content;
      }
    }
  }

  .second {
    width: 100%;
    height: calc(100% - 24rem);
    overflow: auto;
    margin-top: 1rem;
    border-top-right-radius: 0.8rem;
    border-top-left-radius: 0.8rem;

    .songBox {
      width: 100%;
      height: 100px;
    }

    .type1Bg {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .type2Bg {
      background-color: rgba(18, 18, 18, 0.22);
    }
  }
}

.active {
  svg {
    path {
      fill: $error;
      stroke: $error;
    }
  }
}

.textBox3 {
  max-width: 30rem;
  display: flex;
}

.text3 {
  width: 10rem !important;
  overflow: hidden;
  color: $tertiary_white;
  border-left: 1px solid #505050;
  padding-left: 1rem;
}

.like {
  width: 7rem !important;
}
