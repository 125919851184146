@import "../../globalStyles/component.scss";

.pageContent {
  padding-left: 0.5rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .left {
    width: 100%;
    margin-right: 4rem;
    height: 100%;
    padding-left: 2.5rem;

    .header {
      @include titleText();
      display: flex;
      align-items: center;
      justify-content: space-between;

      :nth-child(1) {
        display: flex;
        align-items: center;

        p {
          margin-left: 2rem;
        }
      }

      .headBtn {
        width: 22rem;
        margin-right: 7rem;
      }
    }

    .head {
      width: 100%;

      @include primaryText();
      display: flex;
      align-items: center;
      margin-top: 5rem;
      justify-content: space-between;

      .head2 {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }

      .headImg {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        width: 9.2rem;
        height: 9.2rem;
        background: $secondary_background;
        cursor: pointer;

        .upload {
          display: none;
        }
      }

      .mainBox {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 7rem;
        width: 90%;
        padding-left: 0.5rem;

        .box1 {
          @include secondaryTitleText();
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .box3 {
            display: flex;
            align-items: center;
          }

          .name {
            width: 25rem;
            margin-right: 4rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .box2 {
          display: flex;
          position: relative;
          p {
            color: $tertiary_white;
            margin-right: 4.4rem;
          }
          &:hover {
            cursor: pointer;
          }

          .customTooltip {
            position: absolute;
            top: 100%;
            left: 10%;
            display: none;
            z-index: 5;
            color: #363636;
            background-color: #858585;
            border-radius: 4px;
            padding: 0.2rem 1rem;
          }

          &:hover {
            .customTooltip {
              display: block;
            }
          }
        }
      }

      .editBtn {
        width: 12rem;
        margin-right: 2rem;
        margin-left: 2rem;
      }
    }

    .songsBox {
      color: white;
      height: calc(78% - 5rem);
      margin-top: 3rem;
      overflow: auto;
      // padding-bottom: 2rem;
    }
  }

  .right {
    min-width: 40rem;
    padding: 2rem;

    width: 40%;
    // margin-right: 5rem;
    height: calc(100% - 2rem);
    border: 1px solid $stroke;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .songsContainerClassName {
      height: 50px;
      width: 100%;
      padding: 1.1rem 3rem;
      border-radius: 0.8rem;
      border: 1px solid $stroke;
      background-color: $secondary_background;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .contentContianerClassName {
      margin-left: 2rem;
    }

    .songsListContainer {
      flex: 1 1;
      width: 100%;
      margin-top: 4rem;
      overflow: auto;

      .songListItemContainer {
        padding: 0;
      }
    }
  }
}

.songName {
  max-width: 10rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.backIcon {
  cursor: pointer;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: $tertiary_white;
    }
  }

  &:hover {
    svg {
      path {
        fill: $primary_white;
      }
    }
  }
}

.delete {
  margin-left: 2rem;
}

.addSongBtn {
  width: 20rem;
  margin-top: 70%;
}

.nameInput {
  @include secondaryTitleText();
  width: 25rem;
  height: 3rem;
  background-color: $secondary_background;
  border: 1px solid $stroke;
  border-radius: 4px;
  padding: 4px 7px;
  margin-right: 4rem;
}

// .form {
//   margin-top: 4rem;
//   height: 100%;
//   overflow: auto;

//   .box {
//     @include primaryText();
//     display: flex;
//     align-items: center;
//     margin-bottom: 3rem;

//     p {
//       width: 12rem;
//     }

//     .input {
//       margin-left: 2rem;
//       width: 60rem;
//     }

//     .dropdown {
//       width: 15rem;
//     }

//     .contactBtn {
//       margin-left: 3rem;
//       width: 17rem;
//     }
//   }

//   .lastBox {
//     margin-top: 1rem;
//   }

//   .btn {
//     width: 13.3rem;
//     margin-top: 5rem;
//   }
// }

// .saveBtn {
//   margin-top: 5rem;
//   bottom: 0;
//   width: 17rem;
//   margin-left: 14rem;
//   padding-bottom: 10rem;
// }
