@import "../../globalStyles/component.scss";

.page {
  padding: 0 0;
  height: 100%;
}

.header {
  @include titleText();
  margin-bottom: 6rem;
  padding-left: 4rem;
}

.midBox {
  @include secondaryText();
  padding: 1.2rem 0;
  padding-left: 12.1rem;
  padding-right: 6rem;
  color: $tertiary_white;
  border-bottom: 1px solid #505050;
  width: 100%;
  height: 5rem;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;

  .text {
    @include secondaryText();
    width: 15rem;
    overflow: hidden;
    color: $tertiary_white;
    border-left: 1px solid #505050;
    padding-left: 1rem;
  }

  .text1 {
    text-align: end;
    width: fit-content;
  }
}

.second {
  width: 100%;
  height: calc(100% - 16rem);
  overflow: auto;
  margin-top: 1rem;
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  display: flex;
  flex-direction: column;

  .songBox {
    width: 100%;
    height: 100px;
  }
}
.active {
  svg {
    path {
      fill: $error;
      stroke: $error;
    }
  }
}

.playlistName {
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textBox3 {
  max-width: 30rem;
  display: flex;
}

.text3 {
  width: 10rem !important;
  overflow: hidden;
  color: $tertiary_white;
  border-left: 1px solid #505050;
  padding-left: 1rem;
}

.like {
  width: 7rem !important;
}

.thumbNail {
  height: 4rem;
  width: 4rem;
  font-size: 16px;
}
