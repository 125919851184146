@import "../../globalStyles/component.scss";

.popUpWrapper {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .popUpBg {
    position: fixed;
    background-color: $background;
    opacity: 0.7;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .popUpChildren {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .deleteEventPopUp {
      background-color: $secondary_background;
      padding-left: 2rem;
      padding-right: 2rem;
      min-width: 47rem;
      height: 45vh;
      border-radius: 0.8rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 100;

      .iconBox {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.7rem;
        background-color: $primary_dark_white;

        .icon {
          z-index: 1;

          svg {
            path {
              fill: $primary_white;
            }
          }
        }

        .redStroke {
          svg {
            path {
              fill: $error;
            }
          }
        }
      }

      .redBg {
        background-color: #371b1b;
      }

      .main {
        height: 40vh;
        width: 100%;
        display: flex;
        // align-items: center;
        flex-direction: column;
        // justify-content: center;
        .text2 {
          @include primaryText();
          margin-top: 2rem;
          margin-bottom: 2rem;
        }
        .scroll {
          height: 300px;
          overflow-y: auto;
        }
        .header {
          @include primaryText();
          margin-top: 3rem;
        }

        .btn {
          width: 100%;
          display: flex;
          margin-top: 2rem;
          align-items: center;
          justify-content: flex-end;
          .button {
            width: 14rem;
          }
        }
        .selected {
          background-color: $primary_dark_white;
        }
        .container {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 1rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 10px;
          cursor: pointer;
          &:hover {
            background-color: $primary_dark_white;
          }
          .thumbnailClassName {
            width: 4rem;
            height: 4rem;
            font-size: 1.6rem;
          }
          .text1 {
            @include primaryText();
            width: 15rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 2rem;
          }
        }
      }
    }
  }

  @media (max-width: 1600px) {
    width: 111vw;
    height: 111vh;
  }

  @media (max-width: 1500px) {
    width: 125vw;
    height: 125vh;
  }
}

.popUpOpen {
  display: block !important;
}
