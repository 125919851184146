@import "../../globalStyles/component.scss";

.page {
  // padding: 0 5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  @include titleText();
  color: $primary_white !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;

  p {
    margin-left: 2rem;
  }

  .btn {
    width: 22.5rem;
  }
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .top {
    height: 4rem;
    width: 100%;
    background-color: black;
    z-index: 1;
  }

  width: 100%;
  padding-left: 2rem;
  padding-bottom: 4rem;
  height: 100%;
  overflow: auto;
}
