@import "../../globalStyles/component.scss";

.container {
  width: 100%;
  padding: 1.2rem 0;
  padding-left: 1.6rem;
  display: flex;
  align-items: center;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;

  .thumbNailContainer {
    width: 11rem;
  }

  .extraInfo {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .column {
      width: 15rem;

      .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
      }
    }
    .columnLast {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .lastColumn {
      width: 15rem;
    }
    .ignored {
      svg {
        path {
          fill: $error;
          stroke: $error;
        }
      }
    }
    .text1 {
      @include primaryText();
      width: 15rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media screen and (min-width: 1300px) {
      .text1 {
        overflow: visible;
        text-overflow: ellipsis;
      }
    }

    .text2 {
      @include secondaryText();
      color: $tertiary_white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 5px;
    }

    .addToWatchLater {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
    }

    .text {
      text-align: end;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.backgroundChange {
  background-color: rgba(18, 18, 18, 0.22);
}

.box3 {
  min-width: 15rem;
  max-width: 30rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text3 {
  width: 10rem !important;
  color: $tertiary_white;
}

.like {
  width: 7rem !important;
  z-index: 2;
}
.duration {
  padding-left: 6rem !important;
}

.active {
  svg {
    path {
      fill: $error !important;
      stroke: $error !important;
    }
  }
}

.icon {
  svg {
    path {
      stroke: #4e406e;
      stroke-width: 1.5px !important;
      // fill: 1.5px solid #4e406e;
    }
  }
}

.icon2 {
  svg {
    path {
      stroke: #4e406e;
      fill: #4e406e;
    }
  }
}
