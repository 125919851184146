@import '../variables.scss';

@mixin typography(
  $font-family: $font-family-default,
  $font-weight: $font-weight-default,
  $font-size: $font-size-default,
  $line-height: $line-height-default,
  $color: $primary_white
) {
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $line-height;
  color: $color;
  font-style: normal;
}

@mixin titleText {
  @include typography(
    $font-family: $font-family-mulish,
    $font-weight: $font-weight-title-text,
    $font-size: $font-size-title-text,
    $line-height: $line-height-title-text,
    $color: $primary_white
  );
}

@mixin secondaryTitleText {
  @include typography(
    $font-family: $font-family-mulish,
    $font-weight: $font-weight-secondary-title-text,
    $font-size: $font-size-secondary-title-text,
    $line-height: $line-height-secondary-title-text,
    $color: $secondary_white
  );
}

@mixin buttonText {
  @include typography(
    $font-family: $font-family-mulish,
    $font-weight: $font-weight-button-text,
    $font-size: $font-size-button-text,
    $line-height: $line-height-button-text,
    $color: $primary_white
  );
}

@mixin primaryText {
  @include typography(
    $font-family: $font-family-mulish,
    $font-weight: $font-weight-primary-text,
    $font-size: $font-size-primary-text,
    $line-height: $line-height-primary-text,
    $color: $primary_white
  );
}

@mixin secondaryText {
  @include typography(
    $font-family: $font-family-mulish,
    $font-weight: $font-weight-secondary-text,
    $font-size: $font-size-secondary-text,
    $line-height: $line-height-secondary-text,
    $color: $primary_white
  );
}

@mixin inputBoxText {
  @include typography(
    $font-family: $font-family-mulish,
    $font-weight: $font-weight-inputBox-text,
    $font-size: $font-size-inputBox-text,
    $line-height: $line-height-inputBox-text,
    $color: $primary_white
  );
}

@mixin errorText {
  @include secondaryText();
  color: $error;
}
