@import "../../globalStyles/component.scss";

.page {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10rem;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.container {
  width: 60%;
  height: 60%;
  border: 2px dashed $stroke;
  padding: 7rem 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .iconBox {
    height: 11.8rem;
    width: 11.8rem;
    border-radius: 50%;
    background-color: $secondary_background;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .head {
    @include primaryText();
    margin-top: 1rem;
  }

  .btn {
    width: 20rem;
    margin-top: 3.4rem;
  }
}
