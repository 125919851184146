@import '../../../globalStyles/component.scss';

.dashboardMenuList {
  width: 100%;
  padding: 1.2rem 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    @include primaryText();
    color: $tertiary_white;
    margin: 0 0 0 1rem;
  }

  svg {
    stroke: $tertiary_white;
  }

  &:hover {
    p {
      color: $primary_white;
    }

    svg {
      stroke: $primary_white;
    }
  }
}

.active {
  background-color: $tertiary_background;
  border-right: 7px solid $theme;

  p {
    color: $primary_white;
  }

  svg {
    stroke: $primary_white;
  }
}
