@import '../../globalStyles/component.scss';

.volumeBar {
  width: 9rem;
  height: 0.6rem;
  border-radius: 1rem;
  background-color: $primary_white;
  margin-left: 1rem;
  overflow: hidden;
  -webkit-appearance: none;
  // box-shadow: inset 0 0 5px red;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: $theme;
    box-shadow: -403px 0 0 400px $theme;

    // &:hover {
    //   height: 2rem;
    //   width: 2rem;
    // }
  }

  //   &::-webkit-slider-runnable-track {
  //     background-color: red;
  //   }

  &:hover {
    cursor: pointer;
  }
}
