@import "../../globalStyles/component.scss";

.container {
  padding-top: 1.7rem;
  height: 100%;
  padding-left: 3rem;
  padding-right: 5rem;
  background-color: $secondary_background;
  border-top: 1px solid $stroke;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 2rem;

  .imageBox {
    display: flex;
    flex-direction: row;
    width: 33%;

    img {
      height: 6rem;
      width: 6rem;
      background-color: $theme;
    }

    .extraInfo {
      margin-left: 3rem;
      width: 22rem;

      .songName {
        @include primaryText();
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .writer {
        @include secondaryText();
        color: $tertiary_white;
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .likedIcon {
      margin-top: -0.5rem;
      cursor: pointer;
    }
    .ignoredIcon {
      margin-top: -0.5rem;
      // margin-left: 1rem;
      cursor: pointer;
    }
  }

  .musicBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;

    .iconBox {
      width: 18.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon:hover {
        cursor: pointer;
      }

      .rotate {
        transform: rotate(-180deg);
      }
    }

    .barBox {
      @include primaryText();
      display: flex;
      align-items: center;
      margin-top: 1rem;

      .barTime {
        width: fit-content;
      }

      .musicBar {
        width: 40rem;
        height: 0.6rem;
      }

      @media (max-width: 1600px) {
        margin-top: 0.4rem;
      }
    }
  }

  .volume {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 33%;

    .VolumeIcon:hover {
      cursor: pointer;
    }

    .volumeBar {
      width: fit-content;
      margin-left: 1.2rem;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.activeLike {
  svg {
    path {
      fill: $error;
      stroke: $error;
    }
  }
}

.activeIgnore {
  svg {
    path {
      fill: $error;
      stroke: $error;
    }
  }
}
