@import "../../globalStyles/component.scss";

.container {
  width: 100%;
  height: 7rem;
  padding: 1.5rem 0;
  padding-left: 1rem;
  background-color: $background;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    background-color: $secondary_background;
  }

  img {
    background-color: $theme;
  }

  .imgThumb {
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
  }

  .extraInfo {
    width: 100%;
    margin-left: 2rem;
    justify-content: space-between;

    .text1 {
      @include primaryText();
      max-width: 25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text2 {
      @include secondaryText();
      color: $tertiary_white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }
  }

  .scheduleText {
    .textBox {
      display: flex;

      :nth-child(1) {
        margin-right: 2rem;
      }
    }
  }

  .box {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-right: 2rem;
      cursor: pointer;
    }

    .like {
      svg {
        path {
          fill: $error;
          stroke: $error;
        }
      }
    }

    .removeBtn {
      width: 15rem;
    }
  }
}

.scheduleInfo {
  position: relative;
  cursor: pointer;

  .likedIcon {
    svg {
      path {
        fill: $error;
        stroke: $error;
      }
    }
  }

  .list {
    position: absolute;
    top: 100%;
    right: 1rem;
    height: 10rem;
  }
}

.listPopUp {
  max-width: 30rem;
  padding: 1rem;
}
