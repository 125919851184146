.backdrop {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.5);

  @media (max-width: 1600px) {
    height: 111.111vh;
    width: 111.111vw;
  }

  @media (max-width: 1500px) {
    height: 125vh;
    width: 125vw;
  }
}

.modal {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4px;
  height: 337px;
  width: 430px;
  background-color: #121212;
  &.centerModal {
    top: calc(50% - 165px);
    left: calc(50% - 215px);
  }

  // top: calc(50% - 165px);
  // left: calc(50% - 215px);

  .dropdownList {
    display: grid;
    grid-template-columns: auto auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-right: 20px;

    .weekDays {
      background-color: #121212;
    }

    .container {
      height: 5rem;
      background-color: #121212;
    }
  }

  .dropDown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    margin: auto;
    margin-bottom: 21px;
  }

  .btnWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 100px;
    padding-right: 20px;
  }

  .button {
    width: 31%;
  }
}
