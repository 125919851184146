@import '../../globalStyles/component.scss';

.pageContent {
  padding-left: 5.5rem;
  height: 100%;

  .header {
    @include titleText();
  }

  .form {
    margin-top: 4rem;
    height: 64%;
    overflow: auto;

    .box {
      @include primaryText();
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      p {
        width: 10rem;
      }

      .input {
        margin-left: 2rem;
        width: 45rem;
      }

      .contactBtn {
        margin-left: 3rem;
        width: 17rem;
      }
    }

    .lastBox {
      margin-top: 1rem;
    }
  }

  .logoutBtn {
    position: fixed;
    bottom: 0;
    width: 17rem;
    margin-left: 13rem;
    padding-bottom: 14rem;
  }
}
