@import "../../../globalStyles/component.scss";

.inputBoxContainer {
  width: 100%;

  .mainBox {
    display: flex;
    align-items: center;
    width: 90%;

    .blur {
      color: $primary_white !important;
    }

    &:active {
      .icon {
        svg {
          stroke: $primary_white;
        }
      }
    }

    .inputBox {
      width: 90%;

      .blur {
        color: $primary_white !important;
      }

      input {
        width: 100%;
        border: none;
        background-color: $secondary_background;
        color: $tertiary_white;
        outline-style: none;

        &:focus {
          color: $primary_white;
        }
      }
    }
  }
}

.icon {
  margin-right: 1.5rem;

  svg {
    stroke: $tertiary_white;
  }
}

.active {
  svg {
    path {
      stroke: $primary_white;
    }
  }
}
