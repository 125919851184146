@import "../../globalStyles/component.scss";

.table {
  width: 100%;

  .rowContainer {
    @include secondaryText();
    width: 100%;
    display: flex;

    .thumbnailContainer {
      width: 12rem;
    }

    .columnsContainer {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .columnLast {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .column {
        @include secondaryText();
        width: 15rem;
        overflow: hidden;
        color: $tertiary_white;
        padding-left: 1rem;
      }
    }
  }

  .headerContainer {
    color: $tertiary_white;
    border-bottom: 1px solid #505050;
    margin-top: 2rem;
    height: 5rem;

    .column {
      border-left: 1px solid #505050;
    }
  }
}
