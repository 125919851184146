@import '../../../globalStyles/component.scss';

.pageContent {
  padding-left: 3rem;
  height: 100%;

  .header {
    @include titleText();
    display: flex;
    align-items: center;
    justify-content: space-between;

    :nth-child(1) {
      display: flex;
      align-items: center;

      .backIcon {
        cursor: pointer;

        &:hover {
          svg {
            path {
              fill: $primary_white;
            }
          }
        }
      }

      p {
        margin-left: 2rem;
      }
    }

    .headBtn {
      width: 22rem;
      margin-right: 7rem;
    }
  }

  .head {
    @include primaryText();
    display: flex;
    align-items: center;
    margin-top: 5rem;
    justify-content: space-between;
    margin-left: 1.5rem;

    .head2 {
      display: flex;
      align-items: center;
    }

    .headImg {
      width: 9.2rem;
      height: 9.2rem;
      margin-right: 3.5rem;
      border-radius: 1rem;
    }

    .mainBox {
      display: flex;
      flex-direction: column;
      height: 7rem;

      .box1 {
        @include secondaryTitleText();
        display: flex;
        align-items: center;

        p {
          width: 18rem;
          margin-right: 2rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .box2 {
        display: flex;
        margin-top: 1rem;

        .text1 {
          color: $tertiary_white;
        }

        p {
          margin-right: 4.4rem;
        }
      }
    }
  }

  .form {
    margin-top: 4rem;
    height: 100%;
    overflow: auto;
    margin-left: 1.5rem;

    .box {
      @include primaryText();
      display: flex;
      align-items: center;
      margin-bottom: 3rem;

      .mainText {
        width: 12rem;
      }

      .input {
        margin-left: 2rem;
        width: 60rem;
      }

      .dropdown {
        width: 15rem;
      }

      .contactBtn {
        margin-left: 3rem;
        width: 17rem;
      }
    }

    .lastBox {
      margin-top: 1rem;
    }

    .btn {
      width: 13.3rem;
    }
  }

  .saveBtn {
    margin-top: 7.5rem;
    bottom: 0;
    width: 17rem;
    margin-left: 14rem;
    padding-bottom: 10rem;
  }
}

.editIcon {
  cursor: pointer;

  svg {
    path {
      fill: $tertiary_white;
    }
  }

  &:hover {
    svg {
      path {
        fill: $primary_white;
      }
    }
  }
}

.nameInput {
  @include secondaryTitleText();
  width: 18rem;
  height: 3rem;
  background-color: $secondary_background;
  border: 1px solid $stroke;
  border-radius: 4px;
  padding: 4px 7px;
  margin-right: 2rem;
}
