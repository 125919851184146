@import "../../globalStyles/component.scss";

.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $tertiary_background;
  outline: none;
  border: 1px solid $stroke;
  border-radius: 0.4rem;
  height: 5rem;
  padding-left: 2.5rem;
  padding-right: 1.4rem;

  input {
    @include inputBoxText();
    width: 100%;
    background-color: $tertiary_background;
    color: $primary_white;
    // opacity: 0.55;
    border: none;
    border-radius: 0.4rem;
    outline: none;

    &:focus {
      color: $primary_white;
    }
  }

  .icon {
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      path {
        stroke: $tertiary_white;
      }
    }
  }
}

.active {
  border: 1px solid $theme;
  color: $primary_white;

  .icon {
    svg {
      path {
        stroke: $primary_white;
      }
    }
  }
}

.blur {
  input {
    color: $primary_white;
  }
}

.errorBox {
  border: 1px solid $error;
}

.error {
  margin-top: 5px;
  @include errorText();
}
