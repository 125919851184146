// .loader {
//   width: 20px;
//   height: 20px;
//   border: 2px solid #fff;
//   border-bottom-color: transparent;
//   border-radius: 50%;
//   display: inline-block;
//   box-sizing: border-box;
//   animation: rotation 1.2s linear infinite;
// }

// .blackLoader {
//   border: 2px solid #000;
//   border-bottom-color: transparent;
// }

// @keyframes rotation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

.loader {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: conic-gradient(
    #0000 0%,
    #0000 calc(var(--progress) * 1%),
    #00ff00 calc(var(--progress) * 1%),
    #00ff00 100%
  );
}

.loader::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 66%;
  height: 66%;
  background: #000;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.blackLoader {
  background: conic-gradient(
    #6b3eb4 0%,
    #6b3eb4 calc(var(--progress) * 1%),
    #fff calc(var(--progress) * 1%),
    #fff 100%
  );
}
