@import "../../globalStyles/component.scss";

.container {
  min-width: 10rem;
  width: 100%;
  height: 4rem;

  .center {
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      cursor: no-drop;
    }
  }

  .primaryBtn {
    @include buttonText();
    border-width: 0;
    width: 100%;
    background-color: $theme;
    height: 100%;
    border-radius: 2.5rem;
    font-weight: $font-weight-primary-text;

    &:hover {
      color: $secondary_white;
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.6;
      cursor: no-drop;
    }
  }

  .secondaryBtn {
    background-color: $secondary_background;
    border: 1px solid $stroke;
  }

  .tertiaryBtn {
    background-color: $secondary_background;
    border-radius: 0.4rem;
  }

  svg {
    path {
      fill: $primary_white;
    }
  }

  &:hover {
    svg {
      path {
        fill: $secondary_white;
      }
    }
  }
}

.btnContent {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
  }
}
