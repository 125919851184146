@import '../../../globalStyles/component.scss';

.songListItem {
  width: 100%;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  z-index: 10;

  .leftContainer {
    display: flex;

    .thumbNailClassName {
      height: 4rem;
      width: 4rem;
      font-size: 1.6rem;
    }

    .contentContainer {
      margin-left: 3.6rem;

      .name {
        @include primaryText();
        max-width: 25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .artist {
        @include secondaryText();
        max-width: 25rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .rightContainer {
  }

  &:hover {
    cursor: pointer;
  }
}

.name2 {
  width: 14rem !important;
}
