html,
body {
  background-color: #000;
  padding: 0;
  margin: 0;
  font-family: "Heebo", "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-size: 62.5%; /* now 1rem = 10px */
}

html {
  /* scale: 1.2; */
  /* zoom: 67%; */
  /* transform: scale(1.5) translate(20%); */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

body {
  /* zoom: 90% !important; */
  /* zoom: 0.67; */
  /* height: 100vh;
  /* transform: scaleX(0.8) scaleY(0.8); */
  /* width: 100vw; */

  /* -moz-transform: scale(0.8, 0.8);
  zoom: 0.8;
  zoom: 80%;
  -webkit-transform: scale(0.8, 0.8); */

  /* zoom: '150%';
  transform: scale(0.8, 0.8);
  transform-origin: '50%' '50%';  */
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
  background-color: #313131;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #1e1e1e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #313131;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-color: #313131;
}

p {
  color: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1e1e1e inset !important;
  color: red;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #d1d1d1 !important;
}

/* input[type='range'] {
  overflow: hidden;
  -webkit-appearance: none;
  background-color: white;
  border-radius: 10px;
}

 input[type='range']::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  color: #8658b0;
  margin-top: -1px;
} */
/*
input[type='range']::-webkit-slider-thumb {
  width: 10px;
  -webkit-appearance: none;
  height: 10px;
  cursor: pointer;
  background: #8658b0;
  box-shadow: -80px 0 0 80px #8658b0;
  border-radius: 50%;
  margin-top: -3px;
  overflow: visible;
  z-index: 10;
}


input[type="range"]::-moz-range-progress {
  background-color: #8658b0; 
}
input[type="range"]::-moz-range-track {  
  background-color: white;
}

input[type="range"]::-ms-fill-lower {
  background-color: #8658b0; 
}
input[type="range"]::-ms-fill-upper {  
  background-color: white;
} */

audio {
  width: 300px;
  height: 30px;
}
audio::-webkit-media-controls,
video::-webkit-media-controls {
  width: inherit;
  height: inherit;
  position: relative;
  direction: ltr;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
audio::-webkit-media-controls-enclosure,
video::-webkit-media-controls-enclosure {
  width: 100%;
  max-width: 800px;
  height: 30px;
  flex-shrink: 0;
  bottom: 0;
  text-indent: 0;
  padding: 0;
  box-sizing: border-box;
}
video::-webkit-media-controls-enclosure {
  padding: 0px 5px 5px 5px;
  height: 35px;
  flex-shrink: 0;
}
audio::-webkit-media-controls-panel,
video::-webkit-media-controls-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* We use flex-start here to ensure that the play button is visible even
   * if we are too small to show all controls.
   */
  justify-content: flex-start;
  -webkit-user-select: none;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  text-align: right;
  bottom: auto;
  height: 30px;
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 5px;
  /* The duration is also specified in MediaControlElements.cpp and LayoutTests/media/media-controls.js */
  transition: opacity 0.3s;
}
video:-webkit-full-page-media {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
audio:-webkit-full-page-media,
video:-webkit-full-page-media {
  max-height: 100%;
  max-width: 100%;
}
audio:-webkit-full-page-media::-webkit-media-controls-panel,
video:-webkit-full-page-media::-webkit-media-controls-panel {
  bottom: 0px;
}
audio::-webkit-media-controls-mute-button,
video::-webkit-media-controls-mute-button {
  -webkit-appearance: media-mute-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 35px;
  height: 30px;
  line-height: 30px;
  margin: 0 6px 0 0;
  padding: 0;
  background-color: initial;
  color: inherit;
}
audio::-webkit-media-controls-overlay-enclosure {
  display: none;
}
video::-webkit-media-controls-overlay-enclosure {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 1;
  width: 100%;
  max-width: 800px;
  text-indent: 0;
  box-sizing: border-box;
  overflow: hidden;
}
video::-webkit-media-controls-overlay-play-button {
  -webkit-appearance: media-overlay-play-button;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  width: 80px;
  height: 80px;
  padding: 0;
}
audio::-webkit-media-controls-play-button,
video::-webkit-media-controls-play-button {
  -webkit-appearance: media-play-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: 9px;
  margin-right: 9px;
  padding: 0;
  background-color: initial;
  color: inherit;
}
audio::-webkit-media-controls-timeline-container,
video::-webkit-media-controls-timeline-container {
  -webkit-appearance: media-controls-background;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
  -webkit-user-select: none;
  height: 16px;
  min-width: 0;
}
audio::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-time-remaining-display {
  -webkit-appearance: media-current-time-display;
  -webkit-user-select: none;
  flex: none;
  display: flex;
  border: none;
  cursor: default;
  height: 30px;
  margin: 0 9px 0 0;
  padding: 0;
  line-height: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  color: white;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-decoration: none;
}
audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  -webkit-appearance: media-slider;
  display: flex;
  flex: 1 1 auto;
  height: 8px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: transparent;
  min-width: 25px;
  border: initial;
  color: inherit;
}
audio::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-volume-slider {
  -webkit-appearance: media-volume-slider;
  display: flex;
  /* The 1.9 value was empirically chosen to match old-flexbox behaviour
   * and be aesthetically pleasing.
   */
  flex: 1 1.9 auto;
  height: 8px;
  max-width: 70px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: transparent;
  min-width: 15px;
  border: initial;
  color: inherit;
}
/* FIXME these shouldn't use special pseudoShadowIds, but nicer rules.
 https://code.google.com/p/chromium/issues/detail?id=112508
 https://bugs.webkit.org/show_bug.cgi?id=62218
*/
input[type="range" i]::-webkit-media-slider-container {
  display: flex;
  align-items: center;
  flex-direction: row; /* This property is updated by C++ code. */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(230, 230, 230, 0.35);
  border-radius: 4px;
  background-color: transparent; /* Background drawing is managed by C++ code to draw ranges. */
}
/* The negative right margin causes the track to overflow its container. */
input[type="range" i]::-webkit-media-slider-container > div {
  margin-right: -14px;
}
input[type="range" i]::-webkit-media-slider-thumb {
  margin-left: -7px;
  margin-right: -7px;
}
audio::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-fullscreen-button {
  -webkit-appearance: media-enter-fullscreen-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -5px;
  margin-right: 9px;
  padding: 0;
  background-color: initial;
  color: inherit;
}
audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  -webkit-appearance: media-toggle-closed-captions-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -5px;
  margin-right: 9px;
  padding: 0;
  background-color: initial;
  color: inherit;
}
audio::-webkit-media-controls-fullscreen-volume-slider,
video::-webkit-media-controls-fullscreen-volume-slider {
  display: none;
}
audio::-webkit-media-controls-fullscreen-volume-min-button,
video::-webkit-media-controls-fullscreen-volume-min-button {
  display: none;
}
audio::-webkit-media-controls-fullscreen-volume-max-button,
video::-webkit-media-controls-fullscreen-volume-max-button {
  display: none;
}
video::-webkit-media-text-track-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-bottom: 5px;
  font: 22px sans-serif;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-decoration: none;
  pointer-events: none;
  -webkit-user-select: none;
  word-break: break-word;
  flex: 1 1;
}
video::cue {
  display: inline;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2px 2px;
}
video::-webkit-media-text-track-region {
  position: absolute;
  line-height: 5.33vh;
  writing-mode: horizontal-tb;
  background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 1);
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
video::-webkit-media-text-track-region-container {
  position: relative;
  display: flex;
  flex-flow: column;
  flex-direction: column;
}
video::-webkit-media-text-track-region-container.scrolling {
  transition: top 433ms linear;
}
video::-webkit-media-text-track-display {
  position: absolute;
  overflow: hidden;
  white-space: pre-wrap;
  -webkit-box-sizing: border-box;
  flex: 0 0 auto;
}
video::cue(:future) {
  color: gray;
}
video::-webkit-media-text-track-container b {
  font-weight: bold;
}
video::-webkit-media-text-track-container u {
  text-decoration: underline;
}
video::-webkit-media-text-track-container i {
  font-style: italic;
}
