.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .bodyContainer {
    height: calc(100% - 100px);
    // flex: 1;
    width: 100%;
    display: flex;

    .rightContainer {
      flex: 1;
      display: flex;
      flex-direction: column;

      .searchBar {
        height: 11rem;
        padding: 3rem 5rem;
        width: 100%;
        display: flex;

        .btn {
          border: 1px solid #313131;
          border-radius: 0.8rem;
          overflow: hidden;
          width: 19rem;
          margin-left: 2rem;
          height: 100%;
        }
      }

      .childrenContainer {
        height: calc(100% - 11rem);
        width: 100%;
        overflow: hidden;
        padding: 0rem 5rem;
      }
    }

    @media (max-width: 1600px) {
      zoom: 90%;
    }

    @media (max-width: 1500px) {
      zoom: 80%;
    }
  }

  .footerContainer {
    width: 100vw;
    height: 100px;
    // zoom: 80%;

    @media (max-width: 1600px) {
      zoom: 90%;
      width: 100%;
    }

    @media (max-width: 1500px) {
      zoom: 80%;
      width: 100%;
    }
  }
}

.homepage {
  background: linear-gradient(180deg, #210d4f 33.33%, #040405 100%);
}

.UpdateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  .updateContent {
    padding: 2.5rem;
    background-color: white;
    border-radius: 0.5rem;
    text-align: center;
    max-width: 300px;
    width: 100%;
    height: 150px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 3rem;
    }
  }
}
