@import "../../globalStyles/component.scss";

.editBookingListBox {
  position: relative;
  border-radius: 0.4rem;
  background-color: $secondary_background;
  display: flex;
  align-items: center;
  z-index: 10;
  padding: 0.5rem 0;
}

.box {
  .childBox {
    display: flex;
    align-items: center;
    padding: 0.7rem 3.3rem;

    svg {
      path {
        fill: $tertiary_white;
      }
    }

    p {
      margin-left: 0.5rem;
      font-family: $font-family-mulish;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      color: $tertiary_white;
    }

    &:hover {
      svg {
        path {
          fill: $primary_white;
        }
      }

      p {
        color: $primary_white;
      }
    }
  }
  align-items: center;
}

.backDrop {
  position: fixed;
  cursor: default;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.btn1 {
  display: flex !important;
  justify-content: flex-start !important;
}
