@import '../../globalStyles/component.scss';

.container {
  display: flex;
  height: 20px;
  align-items: center;
  gap: 10px;

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    background-color: $secondary_background;
    border: 1px solid $tertiary_white;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
      border: 1px solid $secondary_white;
    }
  }

  .checked {
    background-color: $secondary_background;
    border: none;

    &:hover {
      svg {
        path {
          fill: $secondary_white;
        }
      }
    }
  }

  .disabled {
    height: 18px;
    width: 18px;
    border-radius: 2px;

    &:hover {
      cursor: no-drop;
    }
  }

  .disableChecked {
    border: none;
  }
}
