@import "../../globalStyles/component.scss";

.dashboard {
  background-color: $secondary_background;
  border-right: 1px solid $stroke;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 300px;
  overflow: hidden;

  .header {
    padding: 0 1.2rem;
    padding-top: 3rem;
    padding-bottom: 1.7rem;
    .logo {
      width: 70%;
      height: auto;
      object-fit: contain;
      margin: 12px 10px;
    }
  }

  .playlist {
    margin-top: 1rem;
    flex: 1;
    overflow-y: scroll;

    padding-left: 4rem;
    margin-bottom: 2rem;

    .playlistTextContainer {
      @include primaryText();
      color: $tertiary_white;
      position: relative;

      .playlistText {
        @include primaryText();
        color: $tertiary_white;
        padding: 1rem 0;
        padding-left: 3rem;
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      .customTooltip {
        position: absolute;
        top: 80%;
        left: 20%;
        display: none;
        z-index: 5;
        color: #363636;
        background-color: #858585;
        border-radius: 4px;
        padding: 0.2rem 1rem;
      }

      &:hover {
        .customTooltip {
          display: block;
        }
      }
    }

    .active {
      color: $primary_white;
    }
  }
}
