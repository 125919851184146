@import "../../globalStyles/component.scss";

.container {
  width: 100%;
  padding: 1.1rem 3rem;
  border-radius: 0.8rem;
  border: 1px solid $stroke;
  background-color: $secondary_background;
  display: flex;
  flex-direction: column;
  position: relative;

  .inputBoxContainer {
    width: 100%;
  }

  .itemContainer {
    position: absolute;
    left: 0;
    top: 50px;
    height: calc(100vh - 11rem);
    width: 100%;
    background: #121212;
    opacity: 0.9;
    border-radius: 8px;
    overflow: auto;
    padding-top: 3rem;
    z-index: 10;
  }

  .mainBox {
    display: flex;
    align-items: center;
    width: 90%;

    .blur {
      color: $primary_white !important;
    }

    &:active {
      .icon {
        svg {
          stroke: $primary_white;
        }
      }
    }
  }

  .icon {
    margin-right: 1.5rem;

    svg {
      stroke: $tertiary_white;
    }
  }

  .inputBox {
    width: 90%;

    .blur {
      color: $primary_white !important;
    }

    input {
      width: 100%;
      border: none;
      background-color: $secondary_background;
      color: $tertiary_white;
      outline-style: none;

      &:focus {
        color: $primary_white;
      }
    }
  }
}

.active {
  svg {
    path {
      stroke: $primary_white;
    }
  }
}

.backDrop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: "transparent";
}

.skeleton {
  margin-left: 5rem;
}

.activeBox {
  border: 1px solid $primary_white !important;
}
