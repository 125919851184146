@import '../../globalStyles/component.scss';

.dropdown {
  width: 100%;
  position: relative;

  .dropdownBtn {
    @include primaryText();
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.6rem;
    border-radius: 0.4rem;
    color: $tertiary_white;
    padding: 0.7rem 0.5rem 0.7rem 2rem;
    background-color: $secondary_background;
    border: 0.1rem solid $stroke;

    .icon {
      transition: all 0.3s ease-out;
      position: absolute;
      right: 0;
      margin-right: 1.2rem;

      svg {
        path {
          stroke: $tertiary_white;
        }
      }
    }

    .iconRotate {
      transform: rotate(-180deg);

      svg {
        path {
          stroke: $primary_white;
        }
      }
    }

    .forSelected {
      color: $primary_white;
    }
  }

  .dropdownContent {
    position: absolute;
    width: 100%;
    top: 110%;
    z-index: 100;
    border: 0.1rem solid $stroke;
    max-height: 18.4rem;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.25);
    border-radius: 0.4rem;
    background-color: $secondary_background;

    .dropdownList {
      @include primaryText();
      width: 100%;
      height: 4.6rem;
      left: 4rem;
      top: 15.5rem;
      border-radius: 0.4rem;
      padding: 1.3rem 1.9rem 1.3rem 1rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        background: $tertiary_background;
      }
    }

    .playListStyle {
      height: 5rem;
      padding: 0 !important;
    }

    .withCheckbox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0;
    }
  }
}

.active {
  border: 1px solid $primary_white !important;
}

.hide {
  display: none;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
