@import "../../globalStyles/component.scss";

.background {
  position: absolute;
  background-color: transparent;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9;
  width: 200vw;
  height: 200vh;
}

.myPlayListPage {
  height: 100%;
  width: 100%;

  .midBox {
    @include secondaryText();
    padding: 1.2rem 0;
    padding-left: 9.1rem;
    padding-right: 6rem;
    color: $tertiary_white;
    border-bottom: 1px solid #505050;
    width: 100%;
    height: 5rem;
    margin-top: 5rem;
    display: flex;
    justify-content: space-between;

    .text {
      @include secondaryText();
      width: 15rem;
      overflow: hidden;
      color: $tertiary_white;
      border-left: 1px solid #505050;
      padding-left: 1rem;
      margin-left: 1rem;
    }

    .middle {
      margin-left: -1rem;
    }

    .text1 {
      text-align: end;
      width: fit-content;
    }
  }

  .songsContainer {
    height: calc(100% - 14.4rem);
    overflow: auto;
    width: 100%;
    border-top-right-radius: 0.8rem;
    border-top-left-radius: 0.8rem;

    .thumbnailClassName {
      width: 4rem;
      height: 4rem;
      font-size: 1.6rem;
    }

    .iconContainer {
      padding-left: 10px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  height: 3.4rem;
  margin-left: 4rem;
  margin-bottom: 6rem;

  p {
    @include titleText();
  }

  .btn {
    width: 21rem;
  }
}
