// COLORS
$primary_white: #d1d1d1;
$primary_dark_white: #383838;
$secondary_white: #ffffff;
$tertiary_white: #949494;
$background: #000000;
$secondary_background: #121212;
$tertiary_background: #1e1e1e;
$stroke: #313131;
$theme: #6b3eb4;
$error: #dd4e47;

// FONT SIZES
$font-size-title-text: 2.8rem;
$font-size-secondary-title-text: 2.4rem;
$font-size-button-text: 1.4rem;
$font-size-primary-text: 1.6rem;
$font-size-secondary-text: 1.4rem;
$font-size-inputBox-text: 1.4rem;
$font-size-default: 1.4rem;
$font-size-large: 2rem;

// LINE HEIGHTS
$line-height-title-text: 3.4rem;
$line-height-secondary-title-text: 3rem;
$line-height-button-text: 2rem;
$line-height-primary-text: 2rem;
$line-height-secondary-text: 2rem;
$line-height-inputBox-text: 2rem;
$line-height-default: 2rem;

// FONT WEIGHTS
$font-weight-title-text: 600;
$font-weight-secondary-title-text: 600;
$font-weight-button-text: 600;
$font-weight-primary-text: 500;
$font-weight-secondary-text: 400;
$font-weight-inputBox-text: 500;
$font-weight-default: 400;

// FONT FAMILY
$font-family-mulish: "Heebo", "Poppins", sans-serif;
$font-family-default: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
