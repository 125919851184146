@import '../../globalStyles/component.scss';

.container {
  width: 100%;
  padding: 1.5rem;
  background-color: $secondary_background;
  display: flex;

  .imgBox {
    height: 4rem;
    width: 4rem;
    background-color: $stroke;
    margin-right: 3.5rem;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .title {
      width: 100%;
      height: 2rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .subTitle {
      width: 30%;
      height: 1.5rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }
}

.playlistItem {
  width: 100%;
  padding: 1.5rem;
  background-color: $secondary_background;
  display: flex;

  .imgBox {
    height: 4rem;
    width: 4rem;
    background-color: $stroke;
    margin-right: 3.5rem;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;

    .title {
      width: 70%;
      margin-top: 1rem;
      height: 2rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }
}

.homepage {
  width: 100%;
  padding: 1.5rem;
  background-color: transparent;
  display: flex;

  .imgBox {
    height: 4rem;
    width: 4rem;
    background-color: $stroke;
    margin-right: 3.5rem;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .title {
      width: 80%;
      height: 2rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .subTitle {
      width: 30%;
      height: 1.5rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
    }
  }
}

.homepageTop {
  width: 100%;
  padding: 1.5rem;
  background-color: transparent;
  display: flex;

  .imgBox {
    height: 15rem;
    width: 15rem;
    background-color: $stroke;
    margin-right: 3.5rem;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  .box {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 70%;

    .title {
      width: 100%;
      height: 2rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
    }

    .subTitle {
      width: 30%;
      height: 1.5rem;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
      margin-top: 1rem;
    }

    .musicBar {
      height: 5rem;
      width: 100%;
      background-color: $stroke;
      animation: skeleton-loading 1s linear infinite alternate;
      margin-top: 3rem;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 19%);
  }
  100% {
    background-color: hsl(0, 0%, 25%);
  }
}

.playlist {
  height: 5rem;
  margin-bottom: 1rem;
  padding: 1.5rem 0;
  padding-left: 3rem;

  .title {
    width: 60%;
    height: 2rem;
    background-color: $stroke;
    animation: skeleton-loading 1s linear infinite alternate;
  }
}
