@import "../../globalStyles/component.scss";

.thumbNail {
  width: 6rem;
  height: 6rem;
  display: flex;
  @include titleText();
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;

  .imageContainer {
    width: 100%;
    height: 100%;
    border-radius: 0.4rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    text-transform: uppercase;
  }
}
